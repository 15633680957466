import React, { useCallback, useRef } from 'react';
import Swiper from 'react-id-swiper';
import { StyledSwiper } from './styles';

const WorkoutPlayerComponent = ({workout, autoplay}) => {
  if(!workout) return null;
  const ref = useRef(null);

  console.log(workout);

  const goNext = useCallback(() => {
    if (ref.current) ref.current.swiper.slideNext();
  }, [ref.current]);

  const goPrev = useCallback(() => {
    if (ref.current) ref.current.swiper.slidePrev();
  }, [ref.current]);

  const swiperProps = {
    noSwiping: true
  };

  return (
    <StyledSwiper>
      <Swiper ref={ref} {...swiperProps}>
        {workout.exercises.map((exercise) => <div key={exercise.exerciseId}>{exercise.exerciseId}</div>)}
      </Swiper>
    </StyledSwiper>
  );
};

export default WorkoutPlayerComponent;
