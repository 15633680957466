import WorkoutPlayer from './component';
import PropTypes from 'prop-types';

WorkoutPlayer.propTypes = {
  workout: PropTypes.object,
  autoplay: PropTypes.bool
};

WorkoutPlayer.defaultProps = {
  workout: null,
  autoplay: false
};

export default WorkoutPlayer;
